import React from 'react';
import moment from 'moment';

type DateRange = { startDate: string; endDate: string; };
type Period = 'weekly' | 'monthly' | 'yearly';



const periodMap: { [key in Period]: moment.unitOfTime.DurationConstructor } = { 'weekly': 'week', 'monthly': 'month', 'yearly': 'year' };



export const generateDateRanges = (period: Period, startDate?: string, endDate?: string): DateRange[] => {
    const ranges: DateRange[] = [];
    let targetDate = initializeTargetDate(startDate);
    const currentDate = initializeCurrentDate(endDate);

    while (shouldContinue(targetDate, currentDate, period)) {
        const range = createRange(targetDate, period);
        ranges.push(range);
        targetDate = moveToNextPeriod(targetDate, period);
        if (targetDate.isAfter(currentDate)) break;
    }

    adjustLastRangeEndDate(ranges, currentDate);
    return ranges;
};



const initializeTargetDate = (startDate?: string): moment.Moment => {
    return moment(startDate || '2024-10-01');
};

const initializeCurrentDate = (endDate?: string): moment.Moment => {
    return moment(endDate || moment().format('YYYY-MM-DD'));
};

const shouldContinue = (targetDate: moment.Moment, currentDate: moment.Moment, period: Period): boolean => {
    return targetDate.isBefore(currentDate) || targetDate.isSame(currentDate, periodMap[period]);
};

const createRange = (targetDate: moment.Moment, period: Period): DateRange => {
    return {
        startDate: targetDate.format('YYYY-MM-DD'),
        endDate: targetDate.clone().endOf(periodMap[period]).format('YYYY-MM-DD'),
    };
};

const moveToNextPeriod = (targetDate: moment.Moment, period: Period): moment.Moment => {
    return targetDate.add(1, periodMap[period]);
};

const adjustLastRangeEndDate = (ranges: DateRange[], currentDate: moment.Moment): void => {
    if (ranges.length > 0 && currentDate.isSameOrAfter(moment(ranges[ranges.length - 1].startDate))) {
        ranges[ranges.length - 1].endDate = currentDate.format('YYYY-MM-DD');
    }
};



export const ReportTooltip = ({ payload, label, active }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className="report-tooltip">
                <p className="report-label">
                    {label}
                </p>
                {payload.map((entry: any) => (
                    <div key={entry.name} className="report-entry" style={{ color: entry.stroke }}>
                        <div className="report-entry-content">
                            <span className="report-entry-name">{entry.name}:</span>
                            <span className="report-entry-value">{entry.value}</span>
                        </div>
                    </div>
                ))}
            </div>
        );
    }
    return null;
};



export const ReportTooltipPercentage = ({ payload, label, active }: any) => {
    if (!active || !payload?.length) return null;
    return (
        <div className="report-tooltip">
            <p className="report-label">{label}</p>
            {payload.map(({ name, value, stroke }: any) => (
                <div key={name} className="report-entry" style={{ color: stroke }}>
                    <div className="report-entry-content">
                        <span className="report-entry-name">{name}:</span>
                        <span className="report-entry-value">{value} %</span>
                    </div>
                </div>
            ))}
        </div>
    );
};