import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { ApplicationState } from '../../store';
import PulseLoader from 'react-spinners/PulseLoader';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ReportTimePeriodControls from './ReportTimePeriodControls';
import { generateDateRanges, ReportTooltip } from './ReportsUtils';

type InactiveChartData = { name: string;[key: string]: string; };
type DateRange = { startDate: string; endDate: string; };
type Period = 'weekly' | 'monthly' | 'yearly';



const StudentReport = () => {

    const { token } = useSelector((state: ApplicationState) => state.autherize.data);
    const [inactiveData, setInactiveData] = useState<InactiveChartData[]>([]);
    const [inactiveDateRanges, setInactiveDateRanges] = useState<DateRange[]>([]);
    const [inactiveTimePeriod, setInactiveTimePeriod] = useState<Period>('monthly');
    const [inactiveCustomStartDate, setInactiveCustomStartDate] = useState<string>('');
    const [inactiveCustomEndDate, setInactiveCustomEndDate] = useState<string>('');
    const [loadingInactiveData, setLoadingInactiveData] = useState<boolean>(false);



    const fetchInactiveData = async () => {
        setLoadingInactiveData(true);
        const allInactiveData: InactiveChartData[] = [];
        for (const range of inactiveDateRanges) {
            const data = await fetchDataForRange(range.endDate);
            if (data) {
                const categorizedData = processFetchedData(data.data);
                allInactiveData.push({
                    name: range.endDate,
                    'Inactive 0': String(categorizedData['0']),
                    'Inactive 2': String(categorizedData['2']),
                    'Inactive 3': String(categorizedData['3']),
                    'Inactive 4': String(categorizedData['4']),
                });
            }
        }
        setInactiveData(allInactiveData);
        setLoadingInactiveData(false);
    };

    const fetchDataForRange = async (endDate: string) => {
        try {
            const params = new URLSearchParams({ endDate });
            const url = `${process.env.REACT_APP_API_URL}/v1/school/inactive?${params.toString()}`;
            return await callApi('GET', url, token);
        } catch (error) {
            console.error(`Error fetching inactive users data for range:`, error);
            return null;
        }
    };

    const processFetchedData = (data: { inactive: number }[]) => {
        const categorizedData: { [key: string]: number } = { '0': 0, '2': 0, '3': 0, '4': 0, };
        data.forEach((entry) => {
            if (entry.inactive !== undefined) {
                const inactiveKey = entry.inactive.toString();
                if (categorizedData.hasOwnProperty(inactiveKey)) categorizedData[inactiveKey] += 1;
            }
        });
        return categorizedData;
    };



    useEffect(() => {
        const inactiveRanges = generateDateRanges(inactiveTimePeriod, inactiveCustomStartDate, inactiveCustomEndDate);
        setInactiveDateRanges(inactiveRanges);
    }, [inactiveTimePeriod, inactiveCustomStartDate, inactiveCustomEndDate]);

    useEffect(() => { if (inactiveDateRanges.length > 0) fetchInactiveData(); }, [inactiveDateRanges]);



    return (
        <div className='content'>
            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Inactivity Report</h2>
            </div>

            {inactiveData.length > 0 && (
                <>
                    <div className='w-100 mb-5'>
                        <div className='report-card-container'>
                            {[
                                { label: 'No Activity', key: 'Inactive 0', color: '#8884d8' },
                                { label: 'Two Weeks', key: 'Inactive 2', color: '#82ca9d' },
                                { label: 'Three Weeks', key: 'Inactive 3', color: '#ffc658' },
                                { label: 'Four+ Weeks', key: 'Inactive 4', color: '#ff7300' },
                            ].map(({ label, key, color }) => (
                                <div className='report-card' key={key}>
                                    <p>{label}</p>
                                    <p className='report-number' style={{ color }}>
                                        {inactiveData[inactiveData.length - 1][key]}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                    <ReportTimePeriodControls
                        timePeriod={inactiveTimePeriod}
                        setTimePeriod={setInactiveTimePeriod}
                        customStartDate={inactiveCustomStartDate}
                        setCustomStartDate={setInactiveCustomStartDate}
                        customEndDate={inactiveCustomEndDate}
                        setCustomEndDate={setInactiveCustomEndDate}
                    />
                </>
            )}

            <div className='w-100 mb-5 report-chart-container' style={{ width: '100%', height: 300 }}>
                {loadingInactiveData ? (
                    <PulseLoader color="#0b7aff" />
                ) : (
                    <ResponsiveContainer width="100%" height="100%">
                        <AreaChart data={inactiveData} margin={{ bottom: 20 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" dy={20} />
                            <YAxis dx={-10} />
                            <Tooltip content={<ReportTooltip />} />
                            <Legend
                                verticalAlign="top" wrapperStyle={{ padding: '10px', }}
                                formatter={(value) => <span style={{ fontWeight: 'bold', color: '#333' }}>{value}</span>}
                            />
                            <Area type="monotone" dataKey="Inactive 0" name="None" stackId="1" stroke="#8884d8" fill="#8884d8" />
                            <Area type="monotone" dataKey="Inactive 2" name="Two Weeks" stackId="1" stroke="#82ca9d" fill="#82ca9d" />
                            <Area type="monotone" dataKey="Inactive 3" name="Three Weeks" stackId="1" stroke="#ffc658" fill="#ffc658" />
                            <Area type="monotone" dataKey="Inactive 4" name="Four+ Weeks" stackId="1" stroke="#ff7300" fill="#ff7300" />
                        </AreaChart>
                    </ResponsiveContainer>
                )}
            </div>
        </div>
    );
};

export default StudentReport;
