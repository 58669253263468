import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { mapGrade } from '../../utils/mapGrade';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest, deleteStudentRequest } from '../../store/approval/actions';
import { StudentInfo } from '../../store/approval/types';
import moment from 'moment';
import StudentFilter from '../../containers/filter/student-filter';
import UserTableSearch from './user-table-search';
import UserPendingList from './user-table-pending';
import pencilIcon from '../../images/icons/pencil.svg';
import userImage from '../../images/user/user-image.png';
import './user-table.css';

interface SchoolRoom { id: number; name: string; }

type DeliveryStatus = 'none' | 'physical' | 'ebook' | 'ignore';

interface Delivery { id: string; user_id: string; stage: string; status: string; }



const UserTable = () => {

    const dispatch = useDispatch();
    const dateInputRef = useRef<HTMLInputElement | null>(null);
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const loginState = useSelector((state: ApplicationState) => state.autherize);

    const [currentPage, setCurrentPage] = useState(1);
    const [deliveries, setDeliveries] = useState<Delivery[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [schoolRooms, setSchoolRooms] = useState<SchoolRoom[]>([]);

    const [expandedNames, setExpandedNames] = useState<Set<string>>(new Set());
    const [expandedNicknames, setExpandedNicknames] = useState<Set<string>>(new Set());
    const [expandedAddresses, setExpandedAddresses] = useState<Set<string>>(new Set());

    const [editingExpirationDateId, setEditingExpirationDateId] = useState<string | null>(null);
    const [expirationInputValues, setExpirationInputValues] = useState<{ [key: string]: string | null }>({});

    const [editingLineId, setEditingLineId] = useState<string | null>(null);
    const [lineInputValues, setLineInputValues] = useState<{ [key: string]: string }>({});

    const [editingParentPhone, setEditingParentPhone] = useState<string | null>(null);
    const [parentPhoneInputValues, setParentPhoneInputValues] = useState<{ [key: string]: string }>({});

    const [editingNote, setEditingNote] = useState<string | null>(null);
    const [noteInputValues, setNoteInputValues] = useState<{ [key: string]: string }>({});

    const [nameSortDir, setNameSortDir] = useState<'asc' | 'desc' | null>('asc');
    const [premiumPlusExpSortDir, setPremiumPlusExpSortDir] = useState<'asc' | 'desc' | null>(null);
    const [registerDateSortDir, setRegisterDateSortDir] = useState<'asc' | 'desc' | null>(null);

    const emptyAddress = '—';
    const { token } = loginState.data;

    const usersPerPage = 10;

    const filteredUsers = approvedList.filter(user => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const fieldsToSearch = [
            user.id?.toString(), user.fullname, user.login, user.address, user.parentPhone
        ];
        return fieldsToSearch.some(field => field?.toLowerCase().includes(lowerCaseSearchTerm));
    });

    const totalPages = Math.ceil(approvedList.length / usersPerPage);
    const maxStage = Math.max(...deliveries.map(delivery => parseInt(delivery.stage)));
    const stages = Array.from({ length: maxStage }, (_, i) => (i + 1).toString());

    const deliveryStatusMap: Record<DeliveryStatus, string> = {
        none: 'ยังไม่ส่ง', physical: 'ส่งหนังสือ', ebook: 'ส่ง E-Book', ignore: 'เพิกเฉย'
    };



    const formatDate = (expireTime: moment.MomentInput) => expireTime ? moment(expireTime).format('D MMM, YY') : '—';

    const handleRejectClick = (data: StudentInfo) => dispatch(deleteStudentRequest(data));

    const renderPaginationButton = (pageNumber: number) => (
        <button
            key={pageNumber} onClick={() => setCurrentPage(pageNumber)}
            className={`user-pagination-button ${pageNumber === currentPage ? 'active' : ''}`}
        >
            {pageNumber}
        </button>
    );

    const toggleName = (id: string) => {
        setExpandedNames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const toggleNickname = (id: string) => {
        setExpandedNicknames(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const toggleAddress = (id: string) => {
        setExpandedAddresses(prev => {
            const newSet = new Set(prev);
            newSet.has(id) ? newSet.delete(id) : newSet.add(id);
            return newSet;
        });
    };

    const fetchDeliveries = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/deliveries`, token);
            setDeliveries(response.deliveries);
        } catch (err) {
            console.error('Error fetching deliveries:', err);
        }
    };

    const handleDeliveryStatus = async (userId: string, stage: string, status: string) => {
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/deliveries/upsert-statuses`, token, { userId, stage, status });
            await fetchDeliveries();
        } catch (err) {
            console.error('Error updating delivery status:', err);
        }
    };

    const handleExpirationDateClick = (userId: string, expireDate: string) => {
        setEditingExpirationDateId(userId);
        setExpirationInputValues(prev => ({ ...prev, [userId]: expireDate }));
        setTimeout(() => { dateInputRef.current?.focus(); }, 0);
    };

    const handleExpirationDateChange = (userId: string, value: string) => {
        setExpirationInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleExpirationDateBlur = async (userId: string) => {
        try {
            const dateToUpdate = expirationInputValues[userId];
            const newDate = (dateToUpdate === '' || dateToUpdate === undefined) ? null : dateToUpdate;
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/update-expire-date`, token, { userId, newDate });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating expiration date:', error);
        } finally {
            setEditingExpirationDateId(null);
        }
    };

    const fetchCourses = async () => {
        try {
            const response = await callApi('GET', `${process.env.REACT_APP_API_URL}/v1/school/school-room`, token);
            setSchoolRooms(response.schoolRooms);
        } catch (error) {
            console.error('Error fetching school rooms:', error);
        }
    };

    const handleCourses = async (userId: string, roomId: string) => {
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/student/room/update`, token, { userId, roomId });
            dispatch(fetchApprovedListRequest());
        } catch (err) {
            console.error('Error updating school room ID:', err);
        }
    };

    const handleLineClick = (userId: string, lineName: string) => {
        setEditingLineId(userId);
        setLineInputValues(prev => ({ ...prev, [userId]: lineName }));
    };

    const handleLineChange = (userId: string, value: string) => {
        setLineInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleLineBlur = async (userId: string) => {
        try {
            const newLineName = lineInputValues[userId];
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/user/update/line`, token, { userId, lineName: newLineName });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating LINE Name:', error);
        } finally {
            setEditingLineId(null);
        }
    };

    const handleParentPhoneClick = (userId: string, parentPhone: string) => {
        setEditingParentPhone(userId);
        setParentPhoneInputValues(prev => ({ ...prev, [userId]: parentPhone }));
    };

    const handleParentPhoneChange = (userId: string, value: string) => {
        setParentPhoneInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleParentPhoneBlur = async (userId: string) => {
        try {
            const newParentPhone = parentPhoneInputValues[userId];
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/user/update/parent-phone`, token, { userId, parentPhone: newParentPhone });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating Parent Phone:', error);
        } finally {
            setEditingParentPhone(null);
        }
    };

    const handleNoteClick = (userId: string, note: string) => {
        setEditingNote(userId);
        setNoteInputValues(prev => ({ ...prev, [userId]: note }));
    };

    const handleNoteChange = (userId: string, value: string) => {
        setNoteInputValues(prev => ({ ...prev, [userId]: value }));
    };

    const handleNoteBlur = async (userId: string) => {
        try {
            const newCourse = noteInputValues[userId];
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/update-course`, token, { userId, newCourse });
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error updating Note:', error);
        } finally {
            setEditingNote(null);
        }
    };

    const getExpireTimeClass = (expireTime: moment.MomentInput) => {
        if (!expireTime) return '';

        const expirationDate = moment(expireTime);
        const now = moment();

        return expirationDate.isBefore(now) ? 'expire-red' :
            expirationDate.isBefore(now.clone().add(2, 'weeks')) ? 'expire-yellow' :
                '';
    };

    const handleSortName = () => {
        setNameSortDir(nameSortDir === 'asc' ? 'desc' : 'asc');
        setPremiumPlusExpSortDir(null);
        setCurrentPage(1);
    };

    const handleSortPremiumExp = () => {
        setPremiumPlusExpSortDir(premiumPlusExpSortDir === 'asc' ? 'desc' : 'asc');
        setNameSortDir(null);
        setCurrentPage(1);
    };

    const sortByName = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const nameA = a.fullname?.toLowerCase() || '';
        const nameB = b.fullname?.toLowerCase() || '';
        return direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    };

    const sortByExpireTime = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.expire_date ? moment(a.expire_date) : moment(0);
        const bDate = b.expire_date ? moment(b.expire_date) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };

    const sortByRegisterDate = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.register_date ? moment(a.register_date) : moment(0);
        const bDate = b.register_date ? moment(b.register_date) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };



    useEffect(() => {
        dispatch(fetchApprovedListRequest());
        fetchDeliveries();
        fetchCourses();
    }, []);

    useEffect(() => { setCurrentPage(1); }, [searchTerm]);



    const currentUsers = filteredUsers
        .slice()
        .sort((a, b) => {
            if (nameSortDir) return sortByName(a, b, nameSortDir);
            return 0;
        })
        .sort((a, b) => {
            if (premiumPlusExpSortDir) return sortByExpireTime(a, b, premiumPlusExpSortDir);
            return 0;
        })
        .sort((a, b) => {
            if (registerDateSortDir) return sortByRegisterDate(a, b, registerDateSortDir);
            return 0;
        })
        .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage);

    return (
        <div>
            <div className="filter-container">
                <div className="user-search-container">
                    <input
                        type="text" placeholder="Search by ID, Name, or Course" value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} className="search-input"
                    />
                </div>
                <StudentFilter />
                {filteredUsers.length > usersPerPage && (
                    <div className="user-pagination">
                        <button
                            onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}
                            className="user-pagination-button"
                        >
                            Previous
                        </button>
                        {Array.from({ length: totalPages }, (_, i) => i + 1).map(renderPaginationButton)}
                        <button
                            onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage === totalPages}
                            className="user-pagination-button"
                        >
                            Next
                        </button>
                    </div>
                )}
            </div>

            <table className="table-container">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="user-table-name" onClick={() => { handleSortName() }}>
                            Name {nameSortDir !== null ? (nameSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap">Nickname</th>
                        <th className="user-table-center-nowrap">LINE Name</th>
                        <th className="user-table-center-nowrap">Grade</th>
                        <th className="user-table-center-nowrap">Course</th>
                        <th className="user-table-center-nowrap">Premium Exp.</th>
                        <th className="user-table-center-nowrap">Premium<br />Auto Renew</th>
                        <th className="user-table-center-nowrap" onClick={() => { handleSortPremiumExp() }}>
                            Premium Plus Exp. {premiumPlusExpSortDir !== null ? (premiumPlusExpSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap" onClick={() => { setRegisterDateSortDir(registerDateSortDir === 'asc' ? 'desc' : 'asc'); }}>
                            Register Date {registerDateSortDir !== null ? (registerDateSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="user-table-center-nowrap">Notes</th>
                        {stages.map((stage) => (
                            <th key={stage} className={`user-table-center-nowrap`}>Stage {stage}</th>
                        ))}
                        <th className="user-table-address">Address</th>
                        <th className="user-table-address">Phone</th>
                        <th className="user-table-center-nowrap">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {currentUsers.length ? (
                        currentUsers.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            const isNameExpanded = expandedNames.has(user.id);
                            const isNicknameExpanded = expandedNicknames.has(user.id);
                            const isExpanded = expandedAddresses.has(user.id);
                            const truncatedName = user.fullname.length > 20 ? `${user.fullname.substring(0, 20)}...` : user.fullname;
                            const truncatedNickname = nickname.length > 20 ? `${nickname.substring(0, 20)}...` : nickname;
                            const truncatedAddress = user.address ? user.address.length > 20 ? `${user.address.substring(0, 20)}...` : user.address : emptyAddress;
                            const formattedExpirationDate = user.expire_date ? moment(user.expire_date).format('DD MMM, YY') : emptyAddress;
                            const isAutoRenewActive = user.renew_date != null && user.renew_date !== '';

                            const userDeliveries = deliveries.filter(delivery => delivery.user_id === user.id);
                            const userStageStatuses: Record<string, string> = {};

                            userDeliveries.forEach(delivery => {
                                userStageStatuses[delivery.stage] = delivery.status || 'none';
                            });

                            return (
                                <tr key={user.id}>
                                    <td className="user-table-center-nowrap">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td className="user-table-name">
                                        <span onClick={() => toggleName(user.id)} style={{ cursor: 'pointer' }}>
                                            {isNameExpanded ? user.fullname : truncatedName}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <span onClick={() => toggleNickname(user.id)} style={{ cursor: 'pointer' }}>
                                            {isNicknameExpanded ? nickname : truncatedNickname}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {editingLineId === user.id ? (
                                            <div className="user-course-container">
                                                <input
                                                    type="text"
                                                    value={lineInputValues[user.id] !== undefined ? lineInputValues[user.id] : user.lineName || ''}
                                                    onChange={(e) => handleLineChange(user.id, e.target.value)}
                                                    onBlur={() => handleLineBlur(user.id)}
                                                    onKeyPress={(e) => { if (e.key === 'Enter') { handleLineBlur(user.id); } }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="user-course-container" onClick={() => handleLineClick(user.id, user.lineName)}>
                                                {user.lineName || '—'}
                                                <img src={pencilIcon} alt="Edit" onClick={() => handleLineClick(user.id, user.lineName)} />
                                            </div>
                                        )}
                                    </td>
                                    <td className="user-table-center-nowrap">{mapGrade(user.grade)}</td>
                                    <td className="user-table-center-nowrap">
                                        <select value={user.room_id} onChange={(e) => handleCourses(user.id, e.target.value)}>
                                            {schoolRooms.map((room, index) => (
                                                <option key={room.id || index} value={room.id}>
                                                    {room.name}
                                                </option>
                                            ))}
                                        </select>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <span className={`user-table-expiration-date ${getExpireTimeClass(user.expire_time)}`}>
                                            {formatDate(user.expire_time)}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <input className="auto-renew-check" type="checkbox" checked={isAutoRenewActive} disabled />
                                    </td>
                                    <td className="user-table-expiration">
                                        {editingExpirationDateId === user.id ? (
                                            <input
                                                ref={dateInputRef} type="date"
                                                value={expirationInputValues[user.id] || formattedExpirationDate}
                                                onChange={(e) => handleExpirationDateChange(user.id, e.target.value)}
                                                onBlur={() => handleExpirationDateBlur(user.id)}
                                                onKeyPress={(e) => { if (e.key === 'Enter') { handleExpirationDateBlur(user.id); } }}
                                            />
                                        ) : (
                                            <div className="user-table-expiration-container" onClick={() => handleExpirationDateClick(user.id, user.expire_date)}>
                                                <span className={`user-table-expiration-date ${getExpireTimeClass(formattedExpirationDate)}`}>{formattedExpirationDate}</span>
                                                <img src={pencilIcon} alt="Edit" onClick={() => handleExpirationDateClick(user.id, user.expire_date)} />
                                            </div>
                                        )}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {user.register_date ? moment(user.register_date).format('DD MMM, YY') : emptyAddress}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {editingNote === user.id ? (
                                            <div className="user-course-container">
                                                <input
                                                    type="text" autoFocus maxLength={255}
                                                    value={noteInputValues[user.id] !== undefined ? noteInputValues[user.id] : (user.course || '')}
                                                    onChange={(e) => handleNoteChange(user.id, e.target.value)}
                                                    onBlur={() => handleNoteBlur(user.id)}
                                                    onKeyPress={(e) => { if (e.key === 'Enter') { handleNoteBlur(user.id) } }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="user-course-container" onClick={() => handleNoteClick(user.id, user.course)}>
                                                {user.course || '—'}
                                                <img src={pencilIcon} alt="Edit" onClick={() => handleNoteClick(user.id, user.course)} />
                                            </div>
                                        )}
                                    </td>
                                    {stages.map((stage) => (
                                        <td key={stage} className={`user-table-center-nowrap`}>
                                            <select
                                                value={userStageStatuses[stage] || 'none'}
                                                onChange={(e) => handleDeliveryStatus(user.id, stage, e.target.value)}
                                            >
                                                {(Object.keys(deliveryStatusMap) as DeliveryStatus[]).map(key => (
                                                    <option key={key} value={key}>
                                                        {deliveryStatusMap[key]}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>
                                    ))}
                                    <td className="user-table-address">
                                        <span
                                            onClick={() => user.address && user.address !== emptyAddress ? toggleAddress(user.id) : null}
                                            style={{ cursor: user.address && user.address !== emptyAddress ? 'pointer' : 'default' }}
                                        >
                                            {isExpanded ? user.address : truncatedAddress}
                                        </span>
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        {editingParentPhone === user.id ? (
                                            <div className="user-course-container">
                                                <input
                                                    type="text"
                                                    value={parentPhoneInputValues[user.id] !== undefined ? parentPhoneInputValues[user.id] : user.parentPhone || ''}
                                                    onChange={(e) => handleParentPhoneChange(user.id, e.target.value)}
                                                    onBlur={() => handleParentPhoneBlur(user.id)}
                                                    onKeyPress={(e) => { if (e.key === 'Enter') { handleParentPhoneBlur(user.id); } }}
                                                />
                                            </div>
                                        ) : (
                                            <div className="user-course-container" onClick={() => handleParentPhoneClick(user.id, user.parentPhone)}>
                                                {user.parentPhone || '—'}
                                                <img src={pencilIcon} alt="Edit" onClick={() => handleParentPhoneClick(user.id, user.parentPhone)} />
                                            </div>
                                        )}
                                    </td>
                                    <td className="user-table-center-nowrap">
                                        <button className="remove-button" onClick={() => handleRejectClick(user)}>
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr><td colSpan={7} className="no-playlists">No users available</td></tr>
                    )}
                </tbody>
            </table>

            <UserPendingList />
            <UserTableSearch />
        </div>
    );
};

export default UserTable;
