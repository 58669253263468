import React, { useState } from 'react';
import CustomerReport from './CustomerReport';
import StudentReport from './StudentReport';
import './index.css';
import logoGrey from '../../images/logo/logo-text-nock-grey.png';
import logoGrey2x from '../../images/logo/logo-text-nock-grey@2x.png';
import logoGrey3x from '../../images/logo/logo-text-nock-grey@3x.png';

const Reports: React.FC = () => {
    const [activeReport, setActiveReport] = useState<number>(1);

    return (
        <div>
            <div className='report-category-buttons'>
                <button onClick={() => setActiveReport(1)} className={activeReport === 1 ? 'active' : ''}>
                    Customer Report
                </button>
                <button onClick={() => setActiveReport(2)} className={activeReport === 2 ? 'active' : ''}>
                    Student Report
                </button>
            </div>

            {activeReport === 1 ? (
                <CustomerReport />
            ) : (
                <StudentReport />
            )}

            <div className='dashboard-logo-image-container'>
                <img src={logoGrey} srcSet={`${logoGrey2x} 2x, ${logoGrey3x} 3x`} alt='' />
            </div>
        </div>
    );
};

export default Reports;
