import React from 'react';
type DateRange = { startDate: string; endDate: string; };
type Period = 'weekly' | 'monthly' | 'yearly';

type TimePeriodControlsProps = {
    timePeriod: Period;
    setTimePeriod: React.Dispatch<React.SetStateAction<Period>>;
    customStartDate: string;
    setCustomStartDate: React.Dispatch<React.SetStateAction<string>>;
    customEndDate: string;
    setCustomEndDate: React.Dispatch<React.SetStateAction<string>>;
};

const ReportTimePeriodControls = ({ timePeriod, setTimePeriod, customStartDate, setCustomStartDate, customEndDate, setCustomEndDate }: TimePeriodControlsProps) => {

    const handleReset = () => {
        setCustomStartDate(''); setCustomEndDate('');
    };

    return (
        <div className='w-100 mb-5 report-time-period-controls'>
            <div className='report-time-period-buttons'>
                <button onClick={() => setTimePeriod('weekly')} className={timePeriod === 'weekly' ? 'active' : ''}>
                    Weekly
                </button>
                <button onClick={() => setTimePeriod('monthly')} className={timePeriod === 'monthly' ? 'active' : ''} >
                    Monthly
                </button>
                <button onClick={() => setTimePeriod('yearly')} className={timePeriod === 'yearly' ? 'active' : ''}>
                    Yearly
                </button>
            </div>
            <div className='report-custom-date-range'>
                <input type="date" value={customStartDate} onChange={(e) => setCustomStartDate(e.target.value)} />
                <input type="date" value={customEndDate} onChange={(e) => setCustomEndDate(e.target.value)} />
                <button type="button" onClick={handleReset}>Reset</button>
            </div>
        </div>
    );
};

export default ReportTimePeriodControls;
