import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callApi } from '../../utils/api';
import { mapGrade } from '../../utils/mapGrade';
import { ApplicationState } from '../../store';
import { fetchApprovedListRequest } from '../../store/approval/actions';
import StudentFilter from '../../containers/filter/student-filter';
import moment from 'moment';
import './table.css';
import './overview-user-table.css';
import userImage from '../../images/user/user-image.png';
import { StudentInfo } from '../../store/approval/types';

interface OverviewUserTableProps {
    weekNo: number | null;
    selectedUserId: number | null;
    setSelectedUserId: (id: number | null) => void;
    roomId: string | null;
}

interface UserReportCheck {
    user_id: string;
    report_check: number;
}



const OverviewUserTable: React.FC<OverviewUserTableProps> = ({ weekNo, selectedUserId, setSelectedUserId, roomId }) => {

    const dispatch = useDispatch();
    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList);
    const { token } = useSelector((state: ApplicationState) => state.autherize.data);
    const [searchQuery, setSearchQuery] = useState('');
    const [nameSortDir, setNameSortDir] = useState<'asc' | 'desc' | null>('asc');
    const [registerDateSortDir, setRegisterDateSortDir] = useState<'asc' | 'desc' | null>(null);
    const [checkboxStates, setCheckboxStates] = useState<{ [key: string]: boolean }>({});
    const [userReportChecks, setUserReportChecks] = useState<UserReportCheck[]>([]);



    const handleRowClick = (e: React.MouseEvent, userId: number) => {
        if ((e.target as HTMLElement).closest('#overview-checkbox-cell') || userId === selectedUserId) return;
        setSelectedUserId(userId);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const filterUsers = (user: StudentInfo, searchQuery: string, roomId: string | null) => {
        const lowerCaseSearchTerm = searchQuery.toLowerCase();
        const fieldsToSearch = [user.id?.toString(), user.fullname, user.login];
        return fieldsToSearch.some(field => field?.toLowerCase().includes(lowerCaseSearchTerm)) && user.room_id === roomId;
    };

    const sortByName = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const nameA = a.fullname?.toLowerCase() || '';
        const nameB = b.fullname?.toLowerCase() || '';
        return direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    };

    const sortByCreateTime = (a: StudentInfo, b: StudentInfo, direction: 'asc' | 'desc'): number => {
        const aDate = a.create_time ? moment(a.create_time) : moment(0);
        const bDate = b.create_time ? moment(b.create_time) : moment(0);
        return direction === 'asc'
            ? aDate.isBefore(bDate) ? -1 : 1
            : aDate.isBefore(bDate) ? 1 : -1;
    };

    const handleSortName = () => {
        setNameSortDir(nameSortDir === 'asc' ? 'desc' : 'asc');
        setRegisterDateSortDir(null);
    };

    const handleSortRegisterDate = () => {
        setRegisterDateSortDir(registerDateSortDir === 'asc' ? 'desc' : 'asc');
        setNameSortDir(null);
    };

    const formatDate = (date: moment.MomentInput) => date ? moment(date).format('D MMM, YY') : '—';

    const handleCheckboxChange = async (e: React.MouseEvent, userId: string, currentReportCheck: number) => {
        e.stopPropagation();
        try {
            await callApi('PUT', `${process.env.REACT_APP_API_URL}/v1/school/user/report/toggle-check`, token, { userId, currentReportCheck, roomId, weekNo });
            await fetchUserReportChecks();
            dispatch(fetchApprovedListRequest());
        } catch (error) {
            console.error('Error toggling Report Check:', error);
        }
    };

    const fetchUserReportChecks = async () => {
        if (!roomId || !weekNo) return;
        try {
            const url = `${process.env.REACT_APP_API_URL}/v1/school/user/report/check?roomId=${roomId}&weekNo=${weekNo}`;
            const response = await callApi('GET', url, token);
            if (!response) throw new Error('Failed to fetch user report checks');
            setUserReportChecks(response.data);
        } catch (error) {
            console.error('Error fetching user report checks:', error);
        }
    };



    useEffect(() => { dispatch(fetchApprovedListRequest()); }, [dispatch]);

    useEffect(() => { if (!roomId || !weekNo) return; fetchUserReportChecks(); }, [roomId, weekNo]);



    const sortedList = approvedList.data ? approvedList.data
        .filter(user => filterUsers(user, searchQuery, roomId))
        .sort((a, b) => {
            if (nameSortDir) return sortByName(a, b, nameSortDir);
            return 0;
        })
        .sort((a, b) => {
            if (registerDateSortDir) return sortByCreateTime(a, b, registerDateSortDir);
            return 0;
        }) : [];

    const reportCounts = useMemo(() => {
        return sortedList.reduce((acc, { id }) => {
            const isReported = userReportChecks.some(report => report.user_id === id && report.report_check === 1);
            acc[isReported ? 'reportedCount' : 'notReportedCount']++;
            return acc;
        }, { reportedCount: 0, notReportedCount: 0 });
    }, [sortedList, userReportChecks]);



    return (
        <div className="overview-user-table-container">
            <div className="report-check-container">
                <div className="report-check-controls-container">
                    <input
                        type="text" placeholder="Search by id or name" className="livestream-table-search-box"
                        value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <StudentFilter />
                </div>
                <div className="report-check-card-container">
                    <div className="report-card">
                        <p>Reported</p>
                        <p className="report-number" style={{ color: '#0073ff' }}>{reportCounts.reportedCount}</p>
                    </div>
                    <div className="report-card">
                        <p>Not Reported</p>
                        <p className="report-number" style={{ color: '#ff7300' }}>{reportCounts.notReportedCount}</p>
                    </div>
                </div>
            </div>

            <table className="table-container overview-user-table">
                <thead>
                    <tr>
                        <th className="user-table-center-nowrap">ID</th>
                        <th className="livestream-table-name" onClick={handleSortName}>
                            Name {nameSortDir !== null ? (nameSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="livestream-table-centered">Nickname</th>
                        <th className="livestream-table-centered">Grade</th>
                        <th className="user-table-center-nowrap" onClick={handleSortRegisterDate}>
                            Register Date {registerDateSortDir !== null ? (registerDateSortDir === 'asc' ? ' ⇑' : ' ⇓') : null}
                        </th>
                        <th className="livestream-table-centered">Report Check</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedList.length > 0 ? (
                        sortedList.map((user) => {
                            const nickname = user.login && user.login !== user.fullname ? user.login : "-";
                            const userId = Number(user.id);
                            const isSelected = selectedUserId === userId;
                            const currentReportCheck = userReportChecks.find(report => report.user_id === user.id)?.report_check || 0;
                            return (
                                <tr
                                    key={user.id} onClick={(e) => handleRowClick(e, userId)}
                                    className={`overview-user-table-row ${isSelected ? 'selected' : ''}`}
                                >
                                    <td className="user-table-center-nowrap">
                                        <img className="user-table-profile-image" src={user.lowPhotoUrl || userImage} alt="" />
                                        {user.id}
                                    </td>
                                    <td>
                                        {user.fullname}
                                    </td>
                                    <td className={`livestream-table-centered`}>
                                        {nickname}
                                    </td>
                                    <td className="livestream-table-centered">
                                        {mapGrade(user.grade)}
                                    </td>
                                    <td className="livestream-table-centered">
                                        {formatDate(user.register_date)}
                                    </td>
                                    <td
                                        className="livestream-table-centered" id="overview-checkbox-cell"
                                        onClick={(e) => { handleCheckboxChange(e, user.id, currentReportCheck); }}
                                    >
                                        <input
                                            type="checkbox"
                                            checked={userReportChecks.some(report => report.user_id === user.id && report.report_check === 1)}
                                            onClick={(e) => { handleCheckboxChange(e, user.id, currentReportCheck); }}
                                        />
                                    </td>
                                </tr>
                            );
                        })
                    ) : (
                        <tr>
                            <td colSpan={3} className="no-playlists">No User Available</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default OverviewUserTable;