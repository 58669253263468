import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import UserTable from '../../components/table/user-table';
import BannedTable from '../../components/table/banned-table';
import ExpirationTable from '../../components/table/expiration-table';
import InactiveTable from '../../components/table/inactive-table';
import PlaylistTable from '../../components/table/playlist-table';
import { ApplicationState } from '../../store';
import moment from 'moment';
import PulseLoader from 'react-spinners/PulseLoader';
import './index.css';
import logoGrey from '../../images/logo/logo-text-nock-grey.png';
import logoGrey2x from '../../images/logo/logo-text-nock-grey@2x.png';
import logoGrey3x from '../../images/logo/logo-text-nock-grey@3x.png';



const Dashboard = () => {

    const approvedList = useSelector((state: ApplicationState) => state.approval.approvedList?.data || []);
    const loginState = useSelector((state: ApplicationState) => state.autherize);
    const [inactiveStudents, setInactiveStudents] = useState<{ studentId: string; inactive: number }[]>([]);
    const [loadingInactive, setLoadingInactive] = useState<boolean>(false);
    const [showAll, setShowAll] = useState(false);

    const { token } = loginState.data;
    const hasBannedUsers = approvedList.some(user => user.state !== 0);
    const hasExpiringUsers = approvedList.some(user => {
        const twoWeeksFromNow = moment().add(2, 'weeks');
        return (
            (moment(user.expire_time).isBefore(twoWeeksFromNow)) ||
            (moment(user.expire_date).isBefore(twoWeeksFromNow))
        );
    });



    useEffect(() => {
        const fetchInactiveStudents = async () => {
            setLoadingInactive(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/school/inactive`, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                });
                if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
                const { data } = await response.json();
                setInactiveStudents(data);
            } catch (error) {
                console.error('Error updating inactive students:', error);
            } finally {
                setLoadingInactive(false);
            }
        };
        if (inactiveStudents.length === 0 && !loadingInactive) fetchInactiveStudents();
    }, []);



    return (
        <div className='content'>

            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Current Users</h2>
                <UserTable />
            </div>

            {hasExpiringUsers && (
                <div className='w-100 mb-5'>
                    <h2 className='dashboard-header'>Expiration Pending Users</h2>
                    <ExpirationTable />
                </div>
            )}

            {loadingInactive ? (
                <>
                    <h2 className="dashboard-header">Inactive Users</h2>
                    <div className="loader-container"><PulseLoader color="#0b7aff" /></div>
                </>
            ) : (
                inactiveStudents.length > 0 && (
                    <div className="w-100 mb-5">
                        <h2 className="dashboard-header">
                            Inactive Users
                            <button className="inactive-button" onClick={() => setShowAll(prev => !prev)} style={{ marginLeft: '10px' }}>
                                {showAll ? 'Hide' : 'Show All'}
                            </button>
                        </h2>
                        <InactiveTable inactiveStudents={inactiveStudents} showAll={showAll} />
                    </div>
                )
            )}

            {hasBannedUsers && (
                <div className='w-100 mb-5'>
                    <h2 className='dashboard-header'>Banned Users</h2>
                    <BannedTable />
                </div>
            )}

            <div className='w-100 mb-5'>
                <h2 className='dashboard-header'>Current Playlists</h2>
                <PlaylistTable />
            </div>

            <div className='dashboard-logo-image-container'>
                <img src={logoGrey} srcSet={`${logoGrey2x} 2x, ${logoGrey3x} 3x`} alt='' />
            </div>
        </div>
    );
};

export default Dashboard;
